<template>
    <div class="w-full p-4 m-0">
        <el-link
                type="primary"
                :underline="false"
                @click.prevent="addUnavailabilityClickHandler"
                class="uppercase font-bold text-sm"
                data-cy="addStaff"
        >
            <fa-icon icon="plus" class="mr-1" /> Add
        </el-link>
    </div>
</template>

<script>
export default {
    name: "AddUnavailability",
    methods: {
        addUnavailabilityClickHandler() {
            this.$emit('add-unavailability')
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div class="c-staff__table editable_table flex flex-col">
        <el-form
            :model="unavailabilityForm"
            :rules="validationRules"
            ref="practiceDetailsForm"
            class="pd-form"
        >
            <h4 class="font-display font-semibold pb-2">Unavailability</h4>

            <el-form-item prop="unavailableUntilFurtherNotice" class="col-span-10">
                <el-checkbox
                    label="Unavailable until further notice"
                    v-model="unavailabilityForm.unavailableUntilFurtherNotice"
                ></el-checkbox>
            </el-form-item>
            <div class="text-display text-sm mb-3">If you have any date periods you will be unavailable, please add below.</div>

            <UnavailabilityTable
                :unavailabilityData="unavailabilityForm.unavailability"
                :userId="unavailabilityForm.id"
                @unavailability-mutation="unavailabilityMutation"
            />
        </el-form>
    </div>
</template>

<script>
import UnavailabilityTable from './UnavailabilityTable'

export default {
    name: "SurveyorUnavailability",
    components: { UnavailabilityTable },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            validationRules: {},
        }
    },
    methods: {
        unavailabilityMutation() {
            // bubbling up the mutation
            this.$emit('unavailability-mutation')
        }
    },
    computed: {
        unavailabilityForm: {
            get() {
                return this.value
            },
            set(unavailabilityForm) {
                this.$emit('input', unavailabilityForm)
            }
        }
    },
}
</script>

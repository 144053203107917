export default {
    methods: {
        goBack() {
            let history = this.defaultRoute
            if (this.routeFrom && this.routeFrom.name != null) {
                history = {...this.routeFrom}
            }
            this.$router.push(history)
        }
    }
}

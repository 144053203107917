<template>
    <div class="container flex flex-col">

        <div class="mx-auto max-w-960 pt-16" v-if="!loading || !firstLoad">
            <div class="flex flex-row w-full">
                <el-form
                    :model="accountSettingsForm"
                    :rules="validationRules"
                    ref="accountSettingsForm"
                    class="pd-form"
                >
                    <h1 class="text-black mr-2 mb-8 text-xl lg:text-2xl text-center" data-cy="accountSettingsTitle">Account Settings</h1>

                    <div class="grid grid-cols-6 gap-4 w-full a-settings mb-8">
<!-- TODO: add ProfilePicture back when functionality for saving the avatar is added -->
<!--                        <ProfilePicture-->
<!--                            :user="accountSettingsForm"-->
<!--                            class="col-span-6 xl:col-span-2 float-left"-->
<!--                            @updated-profile="updatedProfile"-->
<!--                        />-->
                        <div class="col-start-2 col-span-6 xl:col-span-4 xl:col-start-2">
                            <el-form-item prop="email" class="col-span-4">
                                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Email</label>
                                <el-input v-model="accountSettingsForm.email" placeholder="Email" disabled></el-input>
                            </el-form-item>
                        </div>
                        <UserProfile
                            v-model="accountSettingsForm"
                            ref="userProfileForm"
                            class="col-start-2 col-span-6 xl:col-span-4 xl:col-start-2"
                        />

                    </div>
                    <ExtendedProfile
                        v-show="canViewExtendedProfile"
                        v-model="accountSettingsForm"
                        ref="extendedProfileForm"
                        class="mb-10"
                    />
                    <div class="grid grid-cols-4 gap-4 w-full a-settings" v-show="canViewExtendedProfile">
                        <Availability
                            v-model="accountSettingsForm.availability"
                            ref="availabilityForm"
                            class="col-span-4"
                        />
                        <Unavailability
                            v-model="accountSettingsForm"
                            ref="unavailabilityForm"
                            class="col-span-4"
                            @unavailability-mutation="unavailabilityMutation"
                        />
                    </div>

                    <div class="flex flex-row justify-center mt-8">
                        <el-button
                            class="font-bold uppercase w-40 font-display"
                            type="primary-lightest"
                            @click="goBack()"
                        >Cancel</el-button>
                        <el-button
                            class="font-bold uppercase w-40 font-display"
                            type="success"
                            @click="validate()"
                        >Save</el-button>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import ProfilePicture from '@/components/account-settings/ProfilePicture'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import GET_USER from '@/graphql/queries/getUser.gql'
import camelcaseKeys from 'camelcase-keys'
import UserProfile from '@/components/shared/UserProfile'
import Availability from '@/components/account-settings/partials/Availability'
import Unavailability from '@/components/account-settings/partials/Unavailability'
import ExtendedProfile from '@/components/account-settings/partials/ExtendedProfile'
import snakecaseKeys from 'snakecase-keys'
import { mapState } from 'vuex'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import HistoryFns from '@/components/shared/mixins/historyFns'
import Loader from '@/assets/loader.svg'

export default {
    name: "AccountSettings",
    mixins: [HttpMixin, AuthMixin, MessageDialog, HistoryFns],
    components: { Unavailability, Availability, ProfilePicture, UserProfile, ExtendedProfile, Loader },
    apollo: {
        user: {
            query: GET_USER,
            update: data => {
                const user = camelcaseKeys(data.user || {}, {deep: true})
                user.unavailability.forEach(value => { value['dateRange'] = [value.dateFrom, value.dateTo] })
                if (!user.phone) {
                    user.phone = {
                        countryCode: '',
                        phone: '',
                        archived: false
                    }
                }
                if (user.availability && typeof user.availability === 'string') {
                    user.availability = JSON.parse(user.availability)
                }
                if (user.unavailableUntilFurtherNotice === null || user.unavailableUntilFurtherNotice === undefined) {
                    user.unavailableUntilFurtherNotice = false
                }
                if (user.dietaryRequirements == null) {
                    user.dietaryRequirements = ''
                }
                return user
            },
            variables() {
                return {
                    userId: this.id
                }
            },
            skip() {
                return !this.id || !this.canViewProfile
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    data() {
        return {
            id: Number(this.$route.params.id),
            loading: true,
            firstLoad: true,
            defaultAccountSettingsForm: {
                dietaryRequirements: '',
                frequentFlyerNum: '',
                nextOfKin: '',
                languages: '',
                address: {
                    unit: '',
                    street: '',
                    address2: '',
                    suburb: '',
                    postcode: '',
                    state: '',
                    lat: '',
                    lng: '',
                    archived: false,
                },
                unavailability: []
            },
            validationRules: {
            },
            defaultPhoneNumber: {
                countryCode: '',
                phone: '',
                archived: false
            },
            duration: 3000,
            defaultAddress: {
                unit: '',
                street: '',
                address2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: null,
                lng: null,
                archived: false,
            },
            defaultRoute: {
                name: 'PracticeList'
            },
        }
    },
    methods: {
        validate() {
            Promise.all([
                this.$refs.accountSettingsForm.validate(),
                this.$refs.userProfileForm.validate(),
                this.$refs.extendedProfileForm.validate(),
                this.$refs.availabilityForm.validate(),
            ]).then(res => {
                const userObj = {...this.accountSettingsForm}

                if (userObj.phone && (userObj.phone.phone === '' || userObj.phone.phone == null)) {
                    userObj.phone = null
                }
                if (userObj.availability && Array.isArray(userObj.availability)) {
                    userObj.availability = JSON.stringify(userObj.availability)
                }

                //if it's a string then we remove it from the object
                if(typeof userObj.avatar === 'string' || !userObj.avatar) delete  userObj.avatar
                let convertedUserObj = snakecaseKeys(userObj, {deep: true})

                this.$http({
                    url: `web.user/${convertedUserObj.id}/`,
                    // headers: {
                    //     'Content-Type': 'multipart/form-data'
                    // },
                    method: 'PATCH',
                    data: convertedUserObj
                }).then(res => {
                    this.message = 'Settings have been saved.'
                    this.type = 'success'
                    this.$apollo.queries.user.refetch()
                }).catch((err => {
                    this.message = 'Error saving your settings'
                    this.type = 'error'
                })).finally(() => {
                    this.showMessage({duration: this.duration})
                })

            }).catch(err => {
                console.log('error', err)
            })
        },
        unavailabilityMutation() {
            this.$apollo.queries.user.refetch()
        },
        // updatedProfile(userObj) {
        //     this.accountSettingsForm = {...this.accountSettingsForm, ...userObj}
        // },
    },
    beforeMount() {
        // we go back to the Dashboard is we somehow luck on someones profile
        if (!this.canViewProfile) {
            this.$router.push({name: 'dashboard'})
        }
    },
    computed: {
        ...mapState({
            routeFrom: state => state.app.routeFrom,
        }),
        accountSettingsForm() {
            return this.user || this.defaultAccountSettingsForm
        },
        canViewExtendedProfile() {
            return (this.id === this.userId && this.isSurveyor) || this.isAdmin
        },
        canViewProfile() {
            return this.id === this.userId || this.isAdmin
        },
    },
    watch: {
        user() {
            if (!this.accountSettingsForm.phone) this.accountSettingsForm.phone = {...this.defaultPhoneNumber}
            if (!this.accountSettingsForm.address) this.accountSettingsForm.address = {...this.defaultAddress}
            this.firstLoad = false
        },
        '$route.params.id': function (id) {
            this.id = id
            this.$apollo.queries.user.refetch()
        }
    }
}
</script>

<template>
    <el-form
        :model="availabilityForm"
        :rules="validationRules"
        ref="availabilityForm"
        class="pd-form mb-10"
    >
        <h4 class="font-display font-semibold pb-2">Available days of the week</h4>
        <span class="text-display text-sm">Select the days of the week you are available for survey visits.</span>

        <div class="flex flex-row w-full items-start ml-0 mt-3">
            <el-checkbox :indeterminate="indeterminate" v-model="checkAll" @change="handleCheckAllChange">Check all
            </el-checkbox>
        </div>

        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedDays" @change="handleCheckedDaysChange">
            <el-checkbox v-for="day in daysOfTheWeek" :label="day" :key="day">{{day}}</el-checkbox>
        </el-checkbox-group>
    </el-form>
</template>

<script>
export default {
    name: 'AvailabilityComponent',
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            daysOfTheWeek: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday'
            ],
            checkAll: false,
            checkedDays: [],
            availabilityForm: {},
            validationRules: {},
        }
    },
    mounted() {
        this.fillCheckedDays()
    },
    computed: {
        indeterminate() {
            let checkedCount = this.value ? this.value.length : 0
            return checkedCount > 0 && checkedCount < this.daysOfTheWeek.length
        },
    },
    methods: {
        validate() {
            return this.$refs.availabilityForm.validate()
        },
        fillCheckedDays() {
            this.checkedDays = this.value || []
            this.checkAll = this.value && this.value.length === this.daysOfTheWeek.length
        },
        handleCheckAllChange(val) {
            this.checkedDays = val ? this.daysOfTheWeek : []
            this.$emit('input', this.checkedDays)
        },
        handleCheckedDaysChange(value) {
            let checkedCount = value.length
            this.checkAll = checkedCount === this.daysOfTheWeek.length
            this.$emit('input', this.checkedDays)
        }
    },
    watch: {
        value() {
            this.fillCheckedDays()
        }
    }
}
</script>

<style scoped>

</style>

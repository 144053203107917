import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{ref:"unavailabilityTable",staticClass:"c-table unavailability",attrs:{"id":"main__unavailability-table","single-select":"","headers":_vm.headers,"items":_vm.unavailabilityDataArr,"items-per-page":_vm.defaultItemsPerPage,"footer-props":_vm.footerProps,"max-height":"600"},on:{"click:row":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"item.dateRange",fn:function(ref){
var item = ref.item;
return [_c('DateRangePickerCell',{ref:("dateRange" + (item.id)),staticClass:"l-daterange",attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":true,"date-type":'daterange',"display-value":_vm.getDateRangeText(item),"max-date":_vm.maxDate},on:{"edit-row":_vm.toggleEdit},model:{value:(item.dateRange),callback:function ($$v) {_vm.$set(item, "dateRange", $$v)},expression:"item.dateRange"}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("description" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":true,"inputType":"textarea"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"c-staff__table__edit-record ml-2 absolute border-l border-grey-5 w-auto",class:{ 'active-tr': item.active}},[_c('a',{staticClass:"pl-2 mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('i',{staticClass:"el-icon-delete hover:text-red",attrs:{"data-cy":"deleteRecord"}})]),(item.active)?_c('a',{staticClass:"mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-close text-lg text-grey-1 hover:text-orange",attrs:{"data-cy":"cancelEdit"}})]):_vm._e(),(item.active)?_c('a',{staticClass:"mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-check text-lg text-grey-1 hover:text-green",attrs:{"data-cy":"saveRecord"}})]):_vm._e()])]}}],null,true)}),_c('AddUnavailability',{on:{"add-unavailability":_vm.addUnavailability}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
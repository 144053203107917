<template>
    <div :class="`dateRange${cellId}`">
        <el-form
            :model="formInput"
            :rules="rules"
            ref="formInputRef"
            class="c-editable-cell"
        >
            <el-form-item prop="inputValue">
                <DateRangePicker
                    v-show="editActive"
                    :attach="attach || `.dateRange${cellId}`"
                    :placeholder="placeholder"
                    :max-date="maxDate"
                    ref="input"
                    v-model="formInput.inputValue"
                    class="p-0 m-0"
                    type="daterange"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    format="dd/MM/yy"
                    @change="dateRangeChangeHandler"
                >
                </DateRangePicker>
            </el-form-item>
        </el-form>
        <a
            v-show="!editActive"
            class="justify-start p-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue}"
            @click.stop.prevent="cellClickHandler"
        >
            {{ linkText || '&nbsp;' }}
        </a>
    </div>
</template>

<script>
import DateRangePicker from '@/components/partials/DateRangePicker'
import { computed, ref, watch } from '@vue/composition-api'

export default {
    name: "DateRangePickerCell",
    components: { DateRangePicker },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: Number,
            default: null
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        maxDate: {
            type: String,
            default: null
        },
        attach: {
            type: String,
            default: ''
        }
    },
    emits: ['edit-row'],
    setup(props, { emit }) {
        const formInputRef = ref({})
        const focused = ref(false)
        const inputValueRef = ref(props.value)
        const computedIsRequired = computed(() => props.isRequired)
        const computedCellId = computed(() => props.cellId)
        const linkText = computed(() => {
            let text =  props.displayValue || props.value
            // reject anything that's not a date
            if (text && text.constructor.name === 'Date') return ''
            return text || ''
        })
        const formInput = computed({
            get: () => {
                return {inputValue: inputValueRef.value}
            },
            set: (newFormInput) => {
                emit('input', newFormInput.inputValue)
            }
        })
        const rules = ref({
            inputValue: [
                { required: computedIsRequired.value, message: 'Required field', trigger: 'blur' },
                // { required: this.isRequired, message: 'Required field', trigger: 'change' },
            ],
        })

        const cellClickHandler = () => {
            focused.value = true
            emit('edit-row', computedCellId.value)
        }

        const dateRangeChangeHandler = (val) => {
            emit('input', val)
        }

        const validate = () => {
            if (formInputRef.value) return formInputRef.value.validate?.()
        }
        const clearValidate = () => {
            return formInputRef.value.clearValidate?.()
        }

        watch(() => props.value, (newValue) => {
            inputValueRef.value = newValue
        })

        watch(formInput, (newFormInput) => {
            if (newFormInput.inputValue) emit('input', newFormInput.inputValue)
        }, { deep: true })

        return { focused, formInput, rules, formInputRef, linkText, inputValueRef, dateRangeChangeHandler, cellClickHandler, validate, clearValidate }
    }
}
</script>

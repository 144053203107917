<template>
    <!-- need the `data-app` attribute since we aren't using the v-app tag, this helps the vuetify select to know what to attach to-->
    <el-form
        :model="extendedProfileForm"
        :rules="validationRules"
        ref="extendedProfileForm"
    >
        <div class="grid grid-cols-10 gap-6 mb-0 w-full">
            <div class="col-span-5 w-full">
                <Address
                    v-model="extendedProfileForm.address"
                    ref="address"
                ></Address>
            </div>
            <div class="col-span-5 w-full">
                <div class="grid grid-cols-6 gap-2">
                    <el-form-item prop="languages" class="col-span-6 mb-2">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Languages</label>
                        <v-autocomplete
                            v-model="extendedProfileForm.displayLanguages"
                            :items="languages"
                            multiple
                            chips
                            deletable-chips
                            hide-details
                            placeholder="Languages"
                            class="p-0 m-0 w-full"
                            data-cy="userLanguage"
                            :menu-props="{maxHeight: 210}"
                            @change="setSelected"
                        ></v-autocomplete>
                    </el-form-item>
                    <div class="col-span-3">
                        <el-form-item prop="frequentFlyerNum" class="mb-2">
                            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Frequent flyer #</label>
                            <el-input
                                v-model="extendedProfileForm.frequentFlyerNum"
                                placeholder="Frequent flyer #"
                                @blur="updateExtendedProfile"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-span-3">
                        <el-form-item prop="nextOfKin" class="mb-2">
                            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Next of kin</label>
                            <el-input
                                v-model="extendedProfileForm.nextOfKin"
                                placeholder="Full name"
                                @blur="updateExtendedProfile"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item prop="dietaryRequirements" class="col-span-6 mb-2">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Dietary requirements</label>
                        <el-input
                            v-model="extendedProfileForm.dietaryRequirements"
                            placeholder="Dietary requirements"
                            type="textarea"
                            class="mt-2"
                            @blur="updateExtendedProfile"
                            :autosize="{ minRows: 4, maxRows: 8}"
                        ></el-input>
                    </el-form-item>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import Address from '@/components/shared/Address'
import { mapState } from 'vuex'

export default {
    name: "ExtendedProfile",
    components: { Address },
    props: {
        value: Object,
        default: () => {}
    },
    data() {
        return {
            dietaryRequirements: null,
            validationRules: {},
            initialPageLoad: true,
        }
    },
    methods: {
        validate() {
            return Promise.all([this.$refs.extendedProfileForm.validate()])
        },
        setSelected(value) {
            this.extendedProfileForm.languages = value ? value.join(',') : null
            this.updateExtendedProfile()
        },
        updateExtendedProfile() {
            this.$emit('input', this.extendedProfileForm)
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        languages() {
            return this.staticChoices['user.languages'] || []
        },
        extendedProfileForm: {
            get() {
                if (this.value) {
                    const extendedProfile = this.value
                    extendedProfile.displayLanguages = extendedProfile.languages ? extendedProfile.languages.split(',') : []
                    return extendedProfile
                }
                return {}
            },
            set(extendedProfileForm) {
                this.$emit('input', extendedProfileForm)
            }
        }
    },
}
</script>

<template>
    <div>
        <v-data-table
            id="main__unavailability-table"
            single-select
            :headers="headers"
            :items="unavailabilityDataArr"
            :items-per-page="defaultItemsPerPage"
            :footer-props="footerProps"
            class="c-table unavailability"
            max-height="600"
            ref="unavailabilityTable"
            @click:row="handleCurrentChange"
        >
            <template v-slot:[`item.dateRange`]="{ item }">
                <DateRangePickerCell
                    class="l-daterange"
                    :ref="`dateRange${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.dateRange"
                    :isRequired="true"
                    :date-type="'daterange'"
                    :display-value="getDateRangeText(item)"
                    :max-date="maxDate"
                    @edit-row="toggleEdit"
                />
            </template>
            <template v-slot:[`item.description`]="{ item }">
                <EditableCell
                    :ref="`description${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.description"
                    :isRequired="true"
                    inputType="textarea"
                    @edit-row="toggleEdit"
                />
            </template>
            <template v-slot:[`item.editRecord`]="{ item }">
                <div class="c-staff__table__edit-record ml-2 absolute border-l border-grey-5 w-auto" :class="{ 'active-tr': item.active}">
                    <a href="#" @click.prevent="deleteHandler(item)" class="pl-2 mr-2">
                        <i class="el-icon-delete hover:text-red" data-cy="deleteRecord"></i>
                    </a>
                    <a href="#" @click.prevent="cancelHandler" v-if="item.active" class="mr-2">
                        <i class="el-icon-close text-lg text-grey-1 hover:text-orange" data-cy="cancelEdit"></i>
                    </a>
                    <a href="#" @click.prevent="saveHandler" v-if="item.active" class="mr-2">
                        <i class="el-icon-check text-lg text-grey-1 hover:text-green" data-cy="saveRecord"></i>
                    </a>
                </div>
            </template>
        </v-data-table>
        <AddUnavailability @add-unavailability="addUnavailability" />
    </div>
</template>

<script>
import AddUnavailability from '@/components/account-settings/partials/AddUnavailability'
import EditableCell from '@/components/partials/EditableCell'
import DateRangePickerCell from '@/components/partials/DateRangePickerCell'
import { addYears, format, isValid, parseISO } from 'date-fns'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import snakecaseKeys from 'snakecase-keys'

export default {
    name: "UnavailabilityTable",
    mixins: [HttpMixin],
    components: { DateRangePickerCell, EditableCell, AddUnavailability },
    props: {
        unavailabilityData: {
            type: Array,
            default: () => []
        },
        userId: {
            type: Number,
        }
    },
    data() {
        return {
            maxDate: format(addYears(new Date(), 1), 'yyyy-MM-dd'),
            defaultItemsPerPage: 20,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            editModeEnabled: true,
            activeRowId: null,
            activeRowObj: {},
            tableName: 'unavailability',
            headers: [
                { text: 'Date Range', align: 'left', value: 'dateRange' },
                { text: 'Reason', align: 'left', value: 'description' },
                { text: '', align: 'left', value: 'editRecord' },
            ],
            unavailability: [
                {
                    id: 9999,
                    dateRange: [`2020-05-28`, `2020-06-28`],
                    dateFrom: `2020-05-28`,
                    dateTo: `2020-06-28`,
                    description: 'I have my long service leave at this time and I am taking those days to go see family'
                }
            ],
            addRowModel: {
                active: true,
                dateRange: [format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
                description: '',
            },
            newUnavailabilityRowAdded: false,
        }
    },
    mounted() {
        this.unavailability = [...this.unavailabilityData]
        this.$root.$on('delete-unavailability', unavailability => {
            this.hardDeleteUnavailability(unavailability)
        })
    },
    methods: {
        // TODO: move the below functions to a table mixin
        handleCurrentChange(currentRow, e) {
            this.currentRow = currentRow
            e.select(true)
        },
        toggleEdit(val) {
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.unavailability)
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.id === this.activeRowId
            })
            return activeRow
        },
        clearRowSelection() {
            // TODO: when moving, make this a string that is set in the data object
            if (this.$refs.unavailabilityTable) {
                // this.$refs.unavailabilityTable.setCurrentRow()
                this.currentRow = null
            }
        },
        cancelHandler() {
            this.resetTableRow()
            this.activeRowId = null
            this.clearRowSelection()
            this.newUnavailabilityRowAdded = false
        },
        deleteHandler(row) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalDeleteUnavailability')
            this.$store.commit('MODAL_DATA', row)
            this.clearRowSelection()
        },
        filterTable(data) {
            console.log('making active')
            return data.map(item => {
                let isActive = item.id === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            console.log('====== the value to reset', this[this.tableName])
            if(!this.newUnavailabilityRowAdded) {
                // update the data on the row to the original values stored in activeRowObj
                this[this.tableName] = this[this.tableName].map(item => {
                    if (item.id === this.activeRowId) {
                        return this.currentRow
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this[this.tableName] = this[this.tableName].filter(item => {
                    if (item.id !== this.activeRowId) return item
                })
            }
        },
        // end move to a mixin
        validateInputs(id) {
            return Promise.all([
                this.$refs[`dateRange${id}`].validate(),
                this.$refs[`description${id}`].validate(),
            ])
        },
        addUnavailability() {
            this.newUnavailabilityRowAdded = true
            let newId = Math.floor(Math.random() * 100000)
            const addRowModel = {
                ...this.addRowModel,
                id: newId,
                dateFrom: this.addRowModel.dateRange[0],
                dateTo: this.addRowModel.dateRange[1],
            }
            this.unavailability.push(addRowModel)
            this.activeRowId = newId
        },
        saveHandler() {
            // get the currently active row
            const activeObj = this.unavailability.find(item => {
                if (item.id === this.activeRowId) {
                    return this.activeRowObj
                }
            })

            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    activeObj.dateFrom = new Date(activeObj.dateRange[0]).toISOString()
                    activeObj.dateTo = new Date(activeObj.dateRange[1]).toISOString()

                    this.mutationCall(activeObj)
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        mutationCall(obj, deleteUnavailability) {
            let isDelete = !!deleteUnavailability
            let unavailabilityObj = {...obj}

            if (!isDelete) {
                // clean up staff object
                if ('active' in unavailabilityObj) delete unavailabilityObj['active']
                if ('typename' in unavailabilityObj) delete unavailabilityObj['typename']
                unavailabilityObj['archived'] = false
                unavailabilityObj['user'] = this.userId

                unavailabilityObj = snakecaseKeys(unavailabilityObj)
                if (this.newUnavailabilityRowAdded) {
                    delete unavailabilityObj.id
                }
            } else {
                unavailabilityObj['archived'] = true
            }
            const httpOptions = this.getPostOrPatchOptions(unavailabilityObj, 'web.unavailability')
            // force the post

            this.$http(httpOptions).then(res => {
                this.$emit('unavailability-mutation')
            }).catch(err => {
                console.error(err)
                this.resetTableRow()
            }).finally(() => {
                if (isDelete) this.$store.commit('MODAL_IS_VISIBLE', false)
                this.activeRowId = null
                this.clearRowSelection()
                this.newUnavailabilityRowAdded = false
            })
        },
        hardDeleteUnavailability(unavailability) {
            this.mutationCall(unavailability, true)
        },
        getDateRangeText(row) {
            if (!row.dateFrom) return ''
            const dateFrom = parseISO(row.dateFrom)
            const dateTo = parseISO(row.dateTo)
            return isValid(dateFrom) && isValid(dateTo) ?
                `${format(dateFrom, 'dd/MM/yy')} - ${format(dateTo, 'dd/MM/yy')}` : ''
        },
    },
    computed: {
        unavailabilityDataArr() {
            return this.unavailability
        },
    },
    watch: {
        activeRowId() {
            this.unavailability = this.filterTable(this.unavailability)
        },
        unavailabilityData() {
            this.unavailability = [...this.unavailabilityData]
        }
    },
    beforeDestroy() {
        this.$root.$off('delete-unavailability')
    }
}
</script>
